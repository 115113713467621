import React from "react"
import PropTypes from "prop-types"

import Footer from "./footer/footer"
import "../styles/default.scss"

const Layout = ({ children }) => (
  <>
    <div
      style={{
        padding: 0,
      }}
    >
      <main>{children}</main>
    </div>
    <Footer />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
