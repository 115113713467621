import React from "react"
import Img from "gatsby-image"
import { graphql, Link, StaticQuery } from "gatsby"

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterImagesQuery {
        logo: file(relativePath: { eq: "logo_text.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const logo = data.logo.childImageSharp.fluid

      return (
        <footer
          style={{
            padding: "3rem",
            display: `flex`,
            flexDirection: `column`,
          }}
        >
          <div
            style={{
              display: `flex`,
              flexDirection: `column`,
              alignItems: `center`,
              justifyContent: `center`,
            }}
          >
            <Link to="/">
              <Img
                style={{ width: `300px`, marginBottom: "1rem" }}
                fluid={logo}
                alt="Ogado logo"
              />
            </Link>
            <span style={{ color: `white` }}>
              © Ogado {new Date().getFullYear()}
            </span>
          </div>
        </footer>
      )
    }}
  />
)

export default Footer
